

const TestRouter = () => {
    return(
        <div>
            <h1>
                Test User Router
            </h1>
        </div>
    )
}


export default TestRouter;