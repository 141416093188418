import React from 'react'
import LayoutOne from '../../layouts/LayoutOne'

export default function Privacy() {
  return (
    <LayoutOne>
      <div className='container'>
        <h2 style={{textAlign: "center",paddingTop: "20px"}}>Privacy Policy</h2>
        <br/>
        <p>
        The H World Organics insists upon the highest standards for secure transactions and protection of
        your Personal Information and its privacy. Please read the following statement to learn about our
        information gathering and dissemination practices.
        <br />
        Note: Our privacy policy is subject to change at any time without notice. To make sure you are aware
        of any changes, please review this policy periodically.
        <br />
        The H World Organics is committed to protecting and safeguarding your privacy.

        This Privacy Policy describes the types of personal information that is collected on the The H World
        website, how we mutually benefit from such information, how we may use that information and with
        whom we may share the same. The Privacy Policy also describes the measures we take to protect
        the security of this information as well as how you can access, modify or delete your personal
        information at any time. It also explains how you can object to the processing of your personal
        information or to receiving communications about our products and services.
        <br />
        By mere use of the website, you expressly consent to our use and disclosure of your personal
        information in accordance with this Privacy Policy. If you do not agree please do not use or access our
        Site. 
        </p>
        <br />
        <h3>Terms and Conditions</h3>
        <br />
        <h3>1. Collection of Personally Identifiable Information (PII) And Other Information</h3>
        <br />
        <p>
        The H World limits itself to collect information in order to ensure accurate service. Most of the
        information we collect is very basic and is needed to complete a purchase or provide a refund.
        Examples of customer information that may be collected by us include your name, address, telephone
        number, date of birth, email address, descriptions of the items requested or purchased, language
        preference, the IP address and operating system of your computer, and the browser type and version
        being used by you.

        We will not sell, distribute or lease your personal information to third parties.

        We may automatically track certain information about you based upon your behaviour on our site. We
        use this information to do internal research on our users' demographics, interests, and behaviour to
        better understand, protect and serve our users. This information is compiled and analysed on an
        aggregated basis. This information may include the URL that you just came from (whether this URL is
        on our site or not), which URL you next go to (whether this URL is on our site or not), your computer
        browser information, and your IP address.

        Credit Card, Debit Card and Banking Information
        The H World Organics does not collect or store Credit Card or Debit Card or any of your banking
        information as it is directly transmitted through the payment gateway provider to the payment network
        or bank.
        </p>
        <br />
        <h3>2. Registration</h3>
        <br />
        <p>
        In order to take advantage of some features of the site, you must register and create a member
        account which is free of any cost. An online registration form may require you to provide information
        such as your name, email address, and a password.

        Your password is a component of our security system. As such, it is your responsibility to protect it.
        Do not share your password with any third parties. If your password has been compromised for any
        reason, you should change it immediately.
        </p>
        <br />
        <h3>3. Technologies That Allow Us To Customise Your Experience On The H World</h3>
        <br />
        <p>
        We use various technologies to collect information relating to your visit to our website, such as the
        Uniform Resource Locater (URL) that referred you to our website, Internet Protocol address, browser
        type, browser language, the date and time of your request, etc. This enables us to enhance and
        customise your experience on our website. For example, we may collect the Internet Protocol (IP)
        address identifying your computer or device and indicating your geographic region for statistical
        purposes. In some instances, we may use these technologies in combination with the personally
        identifiable information you provide on the website. These technologies may provide a variety of
        information such as whether you have visited Kama Ayurveda before. They also may enable you to
        save your preferences. Each technology is explained below.
        </p>
        <br />
        <h3>
        Web Beacons And Tracking Links
        </h3>
        <br />
        <p>
        Web Beacons (also called clear gifs and pixel tags), tracking links and/or similar technology consist of
        a few lines of code and are embedded on the pages of our website. They are often used in
        combination with Cookies and are often not visible to the user of the website. Web Beacons may relay
        information to third parties such as our service providers and may be used in order to track customer
        response to certain advertising, to better target interactive advertising and to enhance customer
        support and usability. If you reject our Cookies (see Cookie section below), you will prevent Web
        <br />  
        Beacons from relaying this information about you and may limit your use of some of our website
        features.
        </p>
        <br />
        <h3>
        Cookies
        </h3>
        <br />
        <p>
        We use data collection devices such as "cookies" on certain pages of the website to help analyze our
        web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small
        files placed on your hard drive that assist us in providing our services. We offer certain features that
        are only available through the use of a "cookie". We also use cookies to allow you to enter your
        password less frequently during a session. Cookies can also help us provide information that is
        targeted to your interests. Most cookies are "session cookies," meaning that they are automatically
        deleted from your hard drive at the end of a session. You are always free to decline our cookies if your
        browser permits, although in that case you may not be able to use certain features on the website and
        you may be required to reenter your password more frequently during a session.
        </p>
        <br />
        <h3>
        4. Use Of Your Personal Information, Demographic And Profile Data
        </h3>
        <br />
        <p>We use personal information to provide the services you request. To the extent we use your personal
        information to market to you, we will provide you the ability to opt-out of such uses. We use your
        personal information to resolve disputes; troubleshoot problems; help promote a safe service; collect
        money; measure consumer interest in our products and services, inform you about online and offline
        offers, products, services, and updates; customize your experience; detect and protect us against
        error, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described
        to you at the time of collection.
        <br />
        In our efforts to continually improve our product and service offerings, we collect and analyze
        demographic and profile data about our users' activity on our website.
        <br />
        We identify and use your IP address to help diagnose problems with our server, and to administer our
        website. Your IP address is also used to help identify you and to gather broad demographic
        information.
        <br />
        We will occasionally ask you to complete optional online surveys. These surveys may ask you for
        contact information and demographic information (like zip code, age, or income level). We use this
        data to tailor your experience at our site, providing you with content that we think you might be
        interested in and to display content according to your preferences.</p>
        <br />
        <h3>5. How Do We Mutually Benefit From The Personal Information?</h3>
        <br />
        <h3>Marketing Emails</h3>
        <br />
        <p>In case you choose to leave your email address with us we would deliver to you information through
        emails such as our newsletters, surveys or other email messages containing product and event
        information, cosmetics tips or promotions. If at any time you decide that you would not like to receive
        these emails, you may select the 'Unsubscribe' link on the email or edit your information and consent
        to receive email and other communication in the 'Profile' section for registered users.</p>
        <br />
        <h3>
        Text Messages
        </h3>
        <br />
        <p>
        If you have chosen to leave your mobile phone number with us, you may receive text message/SMS
        alerts containing product and event information, cosmetics tips or promotions on your mobile phone.

        Please note that we will never send you any unsolicited text message on your mobile phone. The H
        World does not charge any fee for you to receive any text message from us. However, your mobile
        service provider may charge you for sending and/or receiving text messages and air-time, as well as
        any other standard applicable rates charged by your mobile service provider.
        </p>
        <br />
        <h3>Telephone Calls</h3>
        <br />
        <p>If you have chosen to leave your mobile phone number or landline number on our website, you may
        receive phone calls containing product and event information, cosmetics tips or promotions on your
        mobile phone or landline phone.</p>
        <br />
        <h3>Communications To Serve You</h3>
        <br />
        <p>
        If you have chosen to provide us with your contact information, we may provide you with service
        related announcements by any means, including email, letters and concerning the website or contact
        you regarding your customer service requests or questions. For example, all registered users will
        receive a welcoming email to confirm their registration. These types of communications are necessary
        to serve you, respond to your concerns and to provide the high level of customer service that The H
        World Organics offers its customers.
        </p>
        <br />
        <h3>
        Customised Service
        </h3>
        <br />
        <p>
        We may use your personal information you have chosen to give us to provide you with customised
        service and use of our website. For example, we may suggest products that would be of particular

        interest to you. Such customised information may be conveyed to you by way of emails or text
        messages/sms and phone calls.
        </p>
        <br />
        <h3>
        Special Events, Questionnaires And Surveys
        </h3>
        <p>
        On occasion, The H World may sponsor special events (such as contests, promotions or other
        offerings), questionnaires and surveys. There may be specific rules applicable to these promotions
        that explain how any personal information you provide will be processed. We strongly suggest you
        review any applicable rules before participating. To the extent any special event, questionnaire or
        survey is not subject to specific rules, the processing of personal information is governed by this
        Privacy Policy and the purposes it sets forth.
        </p>
        <br />
        <h3>
        Employment
        </h3>
        <br />
        <p>
        If you provide personal information to us with respect to employment, such as your resume, we will
        treat such personal information as confidential and use it only to consider you for current and future
        employment opportunities and to contact you with respect to employment with us or our affiliates. We
        will retain your information for further recruitment opportunities that may arise, unless you tell us that
        you no longer wish for us to retain your information.
        <br />
        An "Affiliate" is a person or entity that directly or indirectly through one or more intermediaries,
        controls or is controlled by, or is under common control with, our company.
        </p>
        <br />
        <h3>
        Advertisements On The H World
        </h3>
        <br />
        <p>We use third-party advertising companies to serve ads when you visit our website. These companies
        may use information (not including your name, address, email address, or telephone number) about
        your visits to this and other websites in order to provide advertisements about goods and services of
        interest to you. We do not take any responsibility with respect to your visits to such other websites and
        providing any information/s therein.</p>
        <br />
        <h3>Links To Other Sites</h3>
        <br />
        <p>Occasionally, we may make available a link to a third party web site. These links will let you leave the
        portal. The linked sites are not under our control and we are not responsible for the privacy practices
        or the contents of any linked site or any link contained in a linked site, or any changes or updates to
        such sites. We are not responsible for webcasting or any other form of transmission received from any
        linked site. We provide the links to you only as a convenience. We do not endorse the site or its use
        or contents.</p>
        <br />
        <h3>
        Sharing Of Personal Information
        </h3>
        <br />
        <p>
        We may share personal information with our other corporate entities and affiliates who may use the
        said information in accordance with this policy to: help detect and prevent identity theft, fraud and
        other potentially illegal acts; correlate related or multiple accounts to prevent abuse of our services;
        and to facilitate joint or co-branded services that you request where such services are provided by
        more than one corporate entity. If you have any objection to such sharing than we request you should
        not provide us with any information.
        <br />

        We may retain other companies and individuals to perform functions on our behalf consistent with this
        Privacy Policy. Examples include webstore management companies, order processing companies,
        courier companies, data analysis firms, customer support specialists, email vendors, web-hosting
        companies and fulfillment companies (e.g., companies that coordinate mailings). Such third parties
        may be provided with access to personal information needed to perform their functions but may not
        use such information other than on our behalf and in accordance with this Privacy Policy.
        <br />
        In addition, in some instances, you may be offered the opportunity to consent to the sharing of your
        information with a third party such as an event or promotion co-sponsor. If you consent, we will share
        your information with such third party and the information you provide may be used by such third party
        for their own purposes and in accordance with their own policies.
        <br />
        We may disclose personal information if required to do so by law or in the good faith belief that such
        disclosure is reasonably necessary to respond to any court orders, or other legal process. We may
        disclose personal information to law enforcement offices, third party rights owners, or others in the
        good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy;
        respond to claims that an advertisement, posting or other content violates the rights of a third party; or
        protect the rights, property or personal safety of our users or the general public.
        </p>
        <br />
        <h3>
        Business Transfer
        </h3>
        <br />
        <p>
        We and our affiliates will share / sell some or all of your personal information with another business
        entity should we (or our assets) plan to merge with, or be acquired by that business entity. Should
        such a transaction occur that other business entity (or the new combined entity) will be required to
        follow this privacy policy with respect to your personal information.
        </p>
        <br />
        <h3>
        SECURITY PRECAUTIONS
        </h3>
        <br />
        <p>We have put in place reasonable measures to protect the personal information contained in our
        databases. Measures are in place to prevent unauthorized access and maintain appropriate use and
        accuracy. Furthermore, data that is transmitted in association with our online store is encrypted and is
        secured by SSL as per international standard IS/ISO/IEC 27001. Does your retention agency have
        IS/ISO/IEC 27001 certificate?</p>
        <br />
        <h3>
        Transfer Of Data To Other Countries
        </h3>
        <br />
        <p>
        The H World, our Affiliates and third parties who may receive your personal information in accordance
        with this Privacy Policy and the databases in which your personal information is stored shall be
        located in India and are required to honour the privacy representations made in this Privacy Policy
        under applicable laws of this country. In case of transfer of data to any other country, legal protections
        applicable to personal information in the concerned country will apply.
        </p>
        <br />
        <h3>
        Choice/Opt-Out
        </h3>
        <br />
        <p>We provide all users with the opportunity to opt-out of receiving non-essential (promotional,
        marketing-related) communications from us on behalf of our partners, and from us in general, after
        setting up an account.
        <br />
        If you want to remove your contact information from all The H World lists and newsletters, please
        visit:http://www.The H World.in/unsubscribe.php</p>
        <br />
        <h3>
        Policy Change
        </h3>
        <br />
        <p>Our Privacy Policy may change from time to time. We will not reduce your rights under this Privacy
        Policy without your explicit consent. We will post any privacy policy changes on this page and, if the
        changes are significant, we will provide a more prominent notice (including, for certain services, email
        notification of privacy policy changes). We will also keep prior versions of this Privacy Policy in an
        archive for your review.</p>
        <br />
        <h3>Your Comments</h3>
        <br />
        <p>
        We welcome your feedback. If you have questions or comments about our privacy policies, feel free
to drop us a message by writing to us at enquiry@The H World.in.
        </p>
        <br />
        <h3>
        Our Rights
        </h3>
        <br />
        <p>
        We may elect to electronically monitor areas of the portal and may disclose any Content, records, or
        electronic communication of any kind (i) to satisfy any law, regulation, or government request; (ii) if
        such disclosure is necessary or appropriate to operate the portal; or (iii) to protect our rights or
        property or the rights of the users, Sponsors, Providers, Licensors, or Merchants.

        We are not responsible for screening, policing, editing, or monitoring such Content. If notified of
        allegedly infringing, defamatory, damaging, illegal, or offensive Content, we may investigate the

        allegation and determine in our sole discretion whether to remove or request the removal of such
        Content from the portal. We may terminate your access, or suspend your access to all or part of the
        Site, without notice, for any conduct that we, in our sole discretion, believe is in violation of any
        applicable law or is harmful to the interests of another user, a third-party Provider, Merchant, Sponsor,
        Licensor, service provider, or us. Because customer service is paramount to our business, we reserve
        the right to refuse to sell products to you if it reasonably appears to us that you intend to resell the
        products. In addition, we reserve the right to limit quantities of items purchased by each customer.
        </p>
        <br />
        <h3>Children</h3>
        <br />
        <p>The The H World website is not designed for persons under the age of 18 and we do not knowingly
        collect personally identifiable information from anyone under the age of 18. If you are under 18 years
        of age, you may browse The H World, but please do not provide your personal information to us. For
        example, you cannot register. If we become aware that we have inadvertently received personal
        information from a visitor under the age of 18 on the website, we will delete the information from our
        records.</p>
        <br />
        <h3>Your Consent</h3>
        <br />
        <p style={{marginBottom: "50px"}}>By using the Website and/ or by providing your information, you consent to the collection and use of
        the information you disclose on the website in accordance with this Privacy Policy, including but not
        limited to Your consent for sharing your information as per this privacy policy.

        If you believe that any information we are holding on you is incorrect or incomplete, please write to or
        email us as soon as possible, at the above address. We will promptly correct any information found to
        be incorrect.</p>
      </div>
    </LayoutOne>
  )
}
